<template>
  <router-view></router-view>
</template>

<script>
import { onBeforeUnmount } from 'vue'
import { useStore } from 'vuex';

export default {
  components: {},
  setup() {
    const store = useStore()
    const tabs = [
      { label: 'tabs.company_profile', to: { name: 'Company' } },
      { label: 'tabs.staff', to: { name: 'CompanyStaff' } },
    ]
    store.commit('header/setTabs', tabs)
    onBeforeUnmount(() => {
      store.commit('header/setTabs', '')
    })
  }
}
</script>

